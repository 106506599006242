

























import { Vue, Component, Watch } from 'vue-property-decorator';
import { TemplateItem, templateConfig } from '@/api/fileTemplate';
import { Route } from 'vue-router';

@Component
export default class FileSetting extends Vue {
  columns = [
    {
      title: '编号',
      dataIndex: 'index',
    },
    {
      title: '映射名称',
      dataIndex: 'groupName',
    },
    {
      title: '映射编码',
      dataIndex: 'groupCode',
    },
    // {
    //   title: '操作',
    //   dataIndex: 'action',
    //   scopedSlots: { customRender: 'action' },
    // },
  ];
  data: any[] = [];
  id = '';

  @Watch('$route', { immediate: true })
  watchRoute(route: Route): void {
    this.id = route.query.id as string;
    this.fetchData();
  }

  cu(record: TemplateItem | null): void {
    this.$router.push({
      path: '/setting/excel/checkSetting',
      query: {
        id: this.id,
      },
    });
  }

  fetchData(): void {
    templateConfig(this.id).then((res) => {
      this.data = res.result.map((item, i) => ({ ...item, index: i + 1 }));
    });
  }
}
